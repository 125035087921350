export enum ImageScalingTypes {
  MAGAZINE_CARD = '__b297h412f',
  MINICART_MAGAZINE = '__b264h360f',
  MINICART_PRODUCT = '__h165b120m',
  PREMIUM_IMAGE = '__b300h300m',
  PRODUCT_CARD = '__b297h412m',
  QUICKSEARCH_PRODUCT_CARD = '__h165b120m',
  SUBSCRIPTION_OFFER_PAGE = '__b600h900m',
}

/**
 * Returns scaled image src of desired size, selected as second paramter (as enum) of this function
 * (egmonts It has a simple-to-use image scaling functionality. See conflunce page (https://egmontbt.atlassian.net/wiki/spaces/E/pages/14584447005/Images+on+Egmont+CDN) for more information)
 * Add more sizes to enum ImageScalingTypes if needed.
 *
 * @param imageUrl {string} - Egmont CDN image URL
 * @param imageScaling {ImageScalingTypes} - enum
 * @returns {string}
 */
export const getResizedImageFromEgmontCDN = (
  imageUrl: string,
  imageScaling: ImageScalingTypes
) => {
  const period = imageUrl.lastIndexOf('.');
  const egmontImageUrl = imageUrl.substring(0, period);
  const fileExtension = imageUrl.substring(period + 1);
  return `${egmontImageUrl}${imageScaling}.${fileExtension}`;
};

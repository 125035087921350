import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { styled } from 'Theme/stitches.config';
import ProductCard from '../ProductCard/ProductCard';
import Grid from 'DesignSystem/Grids/Grid';
import SubscriptionOfferCardModel from 'Models/SubscriptionOffer/SubscriptionOfferCardModel.interface';
import GiftCardCard from '../GiftCardCard/GiftCardCard';
import GiftCardsCardModel from 'Models/GiftCard/GiftCardsCardModel.interface';

type ProductCardListProps = {
  items:
    | ProductCardModel[]
    | SubscriptionOfferCardModel[]
    | GiftCardsCardModel[];
  rootRef: React.RefObject<HTMLDivElement>;
  giftCard?: boolean;
};
function ProductCardList({ items, giftCard = false }: ProductCardListProps) {
  const isMobile = useMedia(mediaQueryTypes.bpMax1024);
  const showHorizontalScrollOnMobile = false;

  return (
    <Grid
      noGutter
      showScrollbar={isMobile && showHorizontalScrollOnMobile}
      css={
        isMobile && showHorizontalScrollOnMobile
          ? {
              '@bpMax720': {
                display: 'flex',
                flex: '1 1 0px',
              },
            }
          : {
              columnGap: '$s75',
              rowGap: '$s200',
              '@bpMin481': { columnGap: '$s200' },
            }
      }
    >
      {items.map((product, i) => (
        <GridItem key={i}>
          {giftCard ? (
            <GiftCardCard
              key={product.code}
              item={product as GiftCardsCardModel}
            />
          ) : (
            <ProductCard
              key={i + product.code}
              item={product as SubscriptionOfferCardModel | ProductCardModel}
            />
          )}
        </GridItem>
      ))}
    </Grid>
  );
}

const GridItem = styled('div', {
  display: 'grid',
  gridColumnEnd: 'span 2',
  '@bpMin721': {
    gridColumnEnd: 'span 4',
  },
  '@bpMin961': {
    gridColumnEnd: 'span 2',
  },
  '@bpMin1281': {
    gridColumnEnd: 'span 3',
  },
  '@bpMax720': {
    gridColumnEnd: 'span 3',
  },
  '@bpMax480': {
    gridColumnEnd: 'span 2',
  },
  '@bpMax600': {
    gridColumnEnd: 'span 2',
  },
});

export default ProductCardList;

import ProductCardModel from 'Models/ProductCard/ProductCardModel.interface';
import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';
import SubscriptionOfferCardModel from 'Models/SubscriptionOffer/SubscriptionOfferCardModel.interface';
import ProductCardImage from './ProductCardImage';
import PriceInfo from './PriceInfo';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import SearchLinkResultModel from 'Models/Search/SearchLinkResultModel.interface';
import { ImageScalingTypes } from 'Shared/Common/ResizeImageEgmontCDN';
import { FocusVisibleStyle } from 'DesignSystem/Accessibility/Utils';

type ProductCardType = {
  item: ProductCardModel | SubscriptionOfferCardModel | SearchLinkResultModel;
  selectedModel?: string;
  inEditMode?: boolean;
  css?: CSS;
  width?: number; //Will be 1/4 of the content area width if renderd in a Product card list
};

function ProductCard({ item, width, css }: ProductCardType) {
  const { name, nowPrice, wasPrice, url } = item;
  const kexNavigate = useKexNavigate();

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (url) {
      if (event.target instanceof HTMLAnchorElement) {
        // Prevent the default click event for the anchor tag
        event.preventDefault();
      }
      kexNavigate(url);
    }
  };

  const secondaryImage =
    'egmontPremiumMainImage' in item
      ? item.egmontPremiumMainImage
      : 'secondaryImage' in item
      ? item.secondaryImage
      : undefined;

  const youSaveSticker =
    'youSaveSticker' in item ? item.youSaveSticker : undefined;

  return (
    <Card css={width ? { ...css, minW: width } : css} onClick={handleCardClick}>
      <div>
        <ProductCardImage
          image={item.image}
          premiumImage={secondaryImage}
          youSaveSticker={youSaveSticker}
          imageScalingType={ImageScalingTypes.PRODUCT_CARD}
        />
      </div>
      <InfoWrapper>
        <TitleAndSubTitle>
          <CardLink href={url}>{name}</CardLink>
          {'egmontNameOfAdditionalItems' in item &&
            item.egmontNameOfAdditionalItems && (
              <SubTitleText>
                {'+ ' + item.egmontNameOfAdditionalItems}
              </SubTitleText>
            )}
        </TitleAndSubTitle>

        <PriceInfo
          currentPrice={nowPrice}
          wasPrice={
            wasPrice && !wasPrice.isZero ? wasPrice.priceWithSymbol : undefined
          }
        ></PriceInfo>
      </InfoWrapper>
    </Card>
  );
}
const CardLink = styled('a', {
  fontSize: '$fontSize200',
  fontFamily: 'fontSemiBold',
  lineHeight: '$lh24',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  '@bpMax480': {
    fontSize: '$fontSize75',
  },
  '&:focus-visible': {
    outline: 'none !Important',
  },
});

const InfoWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  pt: 2,
  height: '100%',
});

const TitleAndSubTitle = styled('div', {
  mb: 2,
});
const SubTitleText = styled('div', {
  fontSize: '$fontSize75',
  lineHeight: '$lh20',
  fontFamily: 'fontMedium',
  pt: 1,
});
const Card = styled('article', {
  color: '$onSurface',
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  w: 'auto',
  minW: 100,
  height: '100%',
  '&:hover': {
    [`& ${CardLink}`]: {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      textDecorationThickness: ' 2px',
    },
  },
  '&:has(a:focus-visible)': {
    ...FocusVisibleStyle,
  },
  '@bpMax720': {
    pb: 5,
  },
});
export default ProductCard;
